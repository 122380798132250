import './src/fonts/fonts.css';

import React from 'react';
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const wrapRootElement = ({ element }) => (
	<GoogleReCaptchaProvider reCaptchaKey={`${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}>
		<ContentfulLivePreviewProvider locale="en-US" debugMode={true}>
		{element}
		</ContentfulLivePreviewProvider>
	</GoogleReCaptchaProvider>
);

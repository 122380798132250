exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-we-speak-your-language-js": () => import("./../../../src/pages/about/we-speak-your-language.js" /* webpackChunkName: "component---src-pages-about-we-speak-your-language-js" */),
  "component---src-pages-all-areas-of-law-js": () => import("./../../../src/pages/all-areas-of-law.js" /* webpackChunkName: "component---src-pages-all-areas-of-law-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-open-job-vacancies-js": () => import("./../../../src/pages/careers/open-job-vacancies.js" /* webpackChunkName: "component---src-pages-careers-open-job-vacancies-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-free-legal-advice-js": () => import("./../../../src/pages/free-legal-advice.js" /* webpackChunkName: "component---src-pages-free-legal-advice-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-centres-js": () => import("./../../../src/pages/legal-centres.js" /* webpackChunkName: "component---src-pages-legal-centres-js" */),
  "component---src-pages-make-a-claim-js": () => import("./../../../src/pages/make-a-claim.js" /* webpackChunkName: "component---src-pages-make-a-claim-js" */),
  "component---src-pages-partnerships-js": () => import("./../../../src/pages/partnerships.js" /* webpackChunkName: "component---src-pages-partnerships-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-win-js": () => import("./../../../src/pages/win.js" /* webpackChunkName: "component---src-pages-win-js" */),
  "component---src-templates-download-pages-js": () => import("./../../../src/templates/download-pages.js" /* webpackChunkName: "component---src-templates-download-pages-js" */),
  "component---src-templates-general-page-js": () => import("./../../../src/templates/general-page.js" /* webpackChunkName: "component---src-templates-general-page-js" */),
  "component---src-templates-legal-centres-single-pages-js": () => import("./../../../src/templates/legal-centres-single-pages.js" /* webpackChunkName: "component---src-templates-legal-centres-single-pages-js" */),
  "component---src-templates-office-single-pages-js": () => import("./../../../src/templates/office-single-pages.js" /* webpackChunkName: "component---src-templates-office-single-pages-js" */),
  "component---src-templates-resource-single-pages-js": () => import("./../../../src/templates/resource-single-pages.js" /* webpackChunkName: "component---src-templates-resource-single-pages-js" */),
  "component---src-templates-resources-child-js": () => import("./../../../src/templates/resources-child.js" /* webpackChunkName: "component---src-templates-resources-child-js" */),
  "component---src-templates-services-child-js": () => import("./../../../src/templates/services-child.js" /* webpackChunkName: "component---src-templates-services-child-js" */),
  "component---src-templates-services-grandchild-js": () => import("./../../../src/templates/services-grandchild.js" /* webpackChunkName: "component---src-templates-services-grandchild-js" */),
  "component---src-templates-services-parent-js": () => import("./../../../src/templates/services-parent.js" /* webpackChunkName: "component---src-templates-services-parent-js" */),
  "component---src-templates-team-single-pages-js": () => import("./../../../src/templates/team-single-pages.js" /* webpackChunkName: "component---src-templates-team-single-pages-js" */)
}

